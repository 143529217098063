import axios from 'axios'

const API = axios.create({
  baseURL: window.location.hostname.includes("flexvastgoedcrm")
  ? "https://server.kantoorflex.nl/api/rest/v2"
  : "https://server.kantoorflex.nl/api/rest/v2",
  timeout: 30000,
});


export default {

  //Blog
  async getBlogs() {
    try {
      const response = await API.get(`/blog`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },

  //Kennisitems
   async getKennisitems() {
    try {
      const response = await API.get(`/kennis`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },

  async  addKennisitem(data) {
    try {
      const response = await API.post('/kennis', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  

  async deleteKennisItem(kennis) {
    const result = await this.$swal.fire({
      title: "Ben je zeker?",
      text: "Je kunt dit niet herstellen!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ja, verwijder",
    });
    if (result.isConfirmed) {
      await API.delete(
        `/kennis/${kennis.kennis_id}`
 
      );
      this.$swal.fire("Verwijderd!", "Kennis is verwijderd.", "success");
    

    }
  },

  //Reserveringen
  async getTotalReservations() {
    try {
      const response = await API.get(`/appointment`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async getAllAppointments() {
    try {
      const response = await API.get(`/appointment`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async getPendingAppointments() {
    try {
      const response = await API.get(`/appointment/pending`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async getApprovedAppointments() {
    try {
      const response = await API.get(`/appointment/approved`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async getCancelledAppointments() {
    try {
      const response = await API.get(`/appointment/cancelled`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  //Updates
  async getUpdates() {
    try {
      const response = await API.get(`/update`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },

  async  addUpdate(data) {
    try {
      const response = await API.post('/update', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  //Submitform

  async  submitform(data) {
    try {
      const response = await API.post('/send/mail', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
}