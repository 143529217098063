<template>
  <Knowledge />
</template>

<script>
import Knowledge from "@/components/user/Knowledge";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Knowledge,
  },
  metaInfo: {
    title: "Informatie over het reserveren| Kantoofflex",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>