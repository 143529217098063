<template>
  <div>
    <v-container style="padding-top: 4rem">
      <div>
        <div style="margin-bottom:10px">
          <v-btn color="primary" @click="goHome()">
            Ga terug
          </v-btn>
        </div>
        <!-- <span v-if="items.lenght < 1"> <h4 class="text-center">Er zijn geen kennisitems beschikbaar </h4></span> -->
        <v-expansion-panels class="mb-6">
          <v-expansion-panel v-for="item in items" :key="item.kennis_id">
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <b class="text-center"> {{ item.title }}</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ item.info }}</v-expansion-panel-content
            >
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-container>
  </div>
</template>

<script>
import api from "../api/api";
export default {
  data() {
    return {
      items: [],
    };
  },
  async mounted() {
    api.getKennisItems().then((data) => {
      this.items = data;
    });
  },
  methods: {
    goHome() {
      this.$router.push("/KlantPortaal");
    },
  },
};
</script>
